import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import '../App.css';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import $ from 'jquery';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import battle from '../assets/battle.png';
import secretGif from '../assets/logo-up.png';
import blue from '../assets/blue.png';
import orange from '../assets/orange.png';
import telegram from '../assets/telegram.png';
import popper from '../assets/party-popper.png';
import Countdown from 'react-countdown';
import '../counter.css';
import logoUp from '../assets/logo-up.png';
import check from '../assets/approve.png';
import bell from '../assets/megaphone.png';
import bell2 from '../assets/coin.png';
import mm from '../assets/MetaMask_Fox.svg.png';
import wc from '../assets/wc.png';

/*
	<div class="countdownMain">
							<div class="countdown">

								<p class="cs">Battlefield Unveiling Shortly!</p>
							</div>
						</div>
*/

const opensea = () => {
	window.open("#");
}

const home = () => {
	window.open("https://frogchain.io/");
}

const mintSite = () => {
	window.open("https://mint.frogchain.io");

}

const stakeSite = () => {
	window.open("https://nftstake.frogchain.io");

}

const gameSite = () => {
	window.open("#");

}

const tweet = () => {
	window.open("https://twitter.com/frogtokenbsc");
}

const tg = () => {
	window.open("https://t.me/frogtokenbsc");
}


const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns
	/*gameEndTime = gameStartTime * 1000 + gameDuration * 1000 + additionalTime * 1000;
	console.log("gameEndTimeTop : " + gameEndTime);
*/
	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

const scoreUpdate = () => {

	$(function () {	// initialize scores
		var team1Score = AquaFrogsCount;
		var team2Score = FireFrogsCount;

		// update scores when necessary, e.g. when user clicks a button
		//$('#team1-add-score').on('click', function () {
		//	team1Score += 10;
		updateProgress();
		//});

		//$('#team2-add-score').on('click', function () {
		//	team2Score += 10;
		//	updateProgress();
		//});

		// update progress bar based on scores
		function updateProgress() {
			var totalScore = team1Score * 1 + team2Score * 1;
			console.log("totalScore : " + totalScore);
			console.log("team1Score : " + team1Score);
			console.log("team2Score : " + team2Score);

			var team1Percentage = (team1Score / totalScore) * 100;
			var team2Percentage = (team2Score / totalScore) * 100;

			$('.team1-progress').css('width', team1Percentage + '%');
			$('.team2-progress').css('width', team2Percentage + '%');
		}
	});
}

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let ingvalue;
let myTokenIDs = [];
let x = '';
let myTokenURLs = '';
let myTokenIDsForm = [];
let z = 0;
let myStakeNFT = '';
let isApproved = false;
let getMyStakedTokens = [];
let getStakedDetailsWindow = 0;
let myStakedTokenCount;
let getRewards = [];
let q = -1;
let getRewardsRounded;
let elementArray = [];
let rewardsTime;
let rewardDetails;
let getMyOwnNFTDetailsWindow = 0;
let myTokenAmount;
let pendingRewards = 0;
let allRewardsRealeasedByOwners = [];
let stakers;
let countOfOverallStakers;
let rewardsAmount;
let rewardsRate;
let AquaFrogsCount;
let gameStartTime;
let FireFrogsCount;
let gameDuration;
let stakingEligibilityTime;
let additionalTime;
let gameIndex;
let stakeFrog_FireFrogs;
let allowance;
let stakeFrog_AquaFrogs;
let gameEndTime;
let isFireFrog;
let isAquaFrog;
let winner;
let claimWinningRewards;
let rewardsTaken;
let gameEnded;
let gameEndedPrevious;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses =

	[

	];

let whitelistAddresses2 = [];

let ABIStaking = [
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIDs",
				"type": "uint256[]"
			}
		],
		"name": "batchStakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIDs",
				"type": "uint256[]"
			}
		],
		"name": "batchUnstakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimWinningRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"name": "onERC721Received",
		"outputs": [
			{
				"internalType": "bytes4",
				"name": "",
				"type": "bytes4"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_additionalTime",
				"type": "uint256"
			}
		],
		"name": "setAdditionalTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalNumber",
				"type": "uint256"
			}
		],
		"name": "setDecimalNumber",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_gameDuration",
				"type": "uint256"
			}
		],
		"name": "setGameDuration",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_additionalTime",
				"type": "uint256"
			}
		],
		"name": "setGameEnd",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_gameIndex",
				"type": "uint256"
			}
		],
		"name": "setGameIndex",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "setGameStart",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_gameStartTime",
				"type": "uint256"
			}
		],
		"name": "setGameStartTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nftContract",
				"type": "address"
			}
		],
		"name": "setNFTContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_stakingEligibilityTime",
				"type": "uint256"
			}
		],
		"name": "setStakingEligibilityTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_winnerPrizeRate",
				"type": "uint256"
			}
		],
		"name": "setWinnerPrizeRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "stakeFrog_AquaFrogs",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "stakeFrog_FireFrogs",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenID",
				"type": "uint256"
			}
		],
		"name": "stakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenID",
				"type": "uint256"
			}
		],
		"name": "unstakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "additionalTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "alreadyStakedNFT",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "AquaFrogsCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "countofMyStakedTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "countOfOverallStakers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "FireFrogsCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "gameDuration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "gameEnded",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "gameIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "gameStartTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "individualStakingCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "individualStakingIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "individualStakingStartTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "isAquaFrog",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "isFireFrog",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "myGameIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "nftOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "originalGameIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "rewardsClaimable_map",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "rewardsClaimable1_map",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "rewardsClaimable2_map",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "rewardsTaken",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakedTokenOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakedTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakers",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "stakingEligibilityTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tokenStakedDuration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tokenStakedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "totalRewardReleased",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winner",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "winnerPrizeRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let addressStaking = "0x033C1975b6696176342438d8D7D7dFa1928F1f54";

let ABIToken = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

let addressToken = "0x8b23c7f7c9D2755683115f620f72eDEC407d5062";

let ABINFT = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_token_Contract",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "allocated",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "allocations",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimOpen",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "claimed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumNFTsHolding",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_claimLimit",
				"type": "uint256"
			}
		],
		"name": "setClaimLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimals",
				"type": "uint256"
			}
		],
		"name": "setDecimals",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minimumNFTsHolding",
				"type": "uint256"
			}
		],
		"name": "setMinimumNFTsHolding",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenAmount",
				"type": "uint256"
			}
		],
		"name": "setTokenAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "share",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "switchOption1",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "switchOption2",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_claimOpen",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_switchOption1",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_switchOption2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "withdrawn",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let addressNFT = "0x72B9Da1BBBDb255F94989A8075e307A2B904657f";

let contractStaking;
let contractToken;
let contractNFT;

class Game extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_ingValue: '',
		_myTokenIDs: [],
		_obj: 'pic',
		_myTokenURLs: '',
		_num: '',
		_value: '',
		_myStakeNFT: '',
		_isApproved: false,
		_getMyStakedTokens: [],
		_getStakedDetailsWindow: 0,
		_myStakedTokenCount: '',
		_getRewards: [],
		_rewardDetails: [],
		_elementArray: [],
		_getMyOwnNFTDetailsWindow: 0,
		_myTokenAmount: '',
		_pendingRewards: 0,
		_newPendingRewards: 0,
		_allRewardsRealeasedByOwners: [],
		_countOfOverallStakers: '',
		_stakers: '',
		_navbarOpen: 0,
		_stakeFrog_AquaFrogsTokens: 0,
		_rewardsAmount: '',
		_rewardsRate: '',
		_AquaFrogsCount: '',
		_gameStartTime: '',
		_FireFrogsCount: '',
		_gameDuration: '',
		_stakingEligibilityTime: '',
		_additionalTime: '',
		_gameIndex: '',
		_stakeFrog_FireFrogsTokens: '',
		_approved: '',
		_gameEndTime: '',
		_isFireFrog: '',
		_isAquaFrog: '',
		_winner: '',
		_claimWinningRewards: '',
		_walletConnect2: 0,
		_rewardsTaken: false,
		_gameEnded: false,
		_gameEndedPrevious: ''
	}

	async componentDidMount() {

		if (localStorage?.getItem('isWalletConnected') === 'true') {
		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {

				const web3mm = new Web3(window.ethereum);

				// Request access to user accounts
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log("metamask on PC")
				// Continue with your DApp logic
				const accounts = await web3mm.eth.getAccounts();
				const account = accounts[0];

				// Fetch data from contracts, etc.
				// ...
				// Fetch data from contracts
				const contractNFT = new web3mm.eth.Contract(ABINFT, addressNFT);
				const contractToken = new web3mm.eth.Contract(ABIToken, addressToken);
				const contractStaking = new web3mm.eth.Contract(ABIStaking, addressStaking);


				// Update your state with the wallet address
				this.setState({ walletAddress: account });

				// Rest of your MetaMask-specific logic can go here
				// ...

				const chainId = 5;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {

							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
										rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

										/*chainName: 'Base Goerli',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://goerli.base.org'],*/

										chainName: 'Goerli  Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
										rpcUrls: ['https://goerli.infura.io/v3/'],

										/*chainName: 'BNB Smart Chain Mainnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'BNB Smart Chain Mainnet', decimals: 18, symbol: 'BNB' },
										rpcUrls: ['https://bsc-dataseed.binance.org'],*/

									},
								],
							});
						}
					}
				}
				try {

					gameIndex = await contractStaking.methods.gameIndex().call();
					this.setState({ _gameIndex: gameIndex });
					console.log("gameIndex:" + gameIndex);

					if (gameIndex != 0) {
						winner = await contractStaking.methods.winner(gameIndex - 1).call();
						this.setState({ _winner: winner });
						console.log("winner:" + winner);


						isAquaFrog = await contractStaking.methods.isAquaFrog(account, gameIndex - 1).call();
						if (isAquaFrog) {
							this.setState({ _isAquaFrog: true });
							console.log("_isAquaFrog : " + this.state._isAquaFrog);
						} else {
							this.setState({ _isAquaFrog: false });
							console.log("_isAquaFrog : " + this.state._isAquaFrog);
						}

						isFireFrog = await contractStaking.methods.isFireFrog(account, gameIndex - 1).call();
						if (isFireFrog) {
							this.setState({ _isFireFrog: true });
							console.log("_isFireFrog : " + this.state._isFireFrog);

						} else {
							this.setState({ _isFireFrog: false });
							console.log("_isFireFrog : " + this.state._isFireFrog);
						}


						rewardsTaken = await contractStaking.methods.rewardsTaken(account, gameIndex - 1).call();
						this.setState({ _rewardsTaken: rewardsTaken });
						console.log("rewardsTaken:" + rewardsTaken);

						if (rewardsTaken) {
							this.setState({ _rewardsTaken: true });
							console.log("rewardsTaken:" + rewardsTaken);
						} else {
							this.setState({ _rewardsTaken: false });
							console.log("rewardsTaken:" + rewardsTaken);
						}

						allowance = await contractToken.methods.allowance(account, addressStaking).call();
						console.log("allowance : " + allowance);

						gameEnded = await contractStaking.methods.gameEnded(gameIndex).call();
						if (gameEnded) {
							this.setState({ _gameEnded: true });
							console.log("gameEnded:" + gameEnded);
						} else {
							this.setState({ _gameEnded: false });
							console.log("gameEnded:" + gameEnded);
						}

						gameEndedPrevious = await contractStaking.methods.gameEnded(gameIndex - 1).call();
						if (gameEndedPrevious) {
							this.setState({ _gameEndedPrevious: true });
							console.log("gameEndedPrevious:" + gameEndedPrevious);
						} else {
							this.setState({ _gameEndedPrevious: false });
							console.log("gameEndedPrevious:" + gameEndedPrevious);
						}

					}

					countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
					this.setState({ _countOfOverallStakers: countOfOverallStakers });
					console.log("countOfOverallStakers:" + countOfOverallStakers);

					AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
					this.setState({ _AquaFrogsCount: AquaFrogsCount });
					console.log("AquaFrogsCount:" + AquaFrogsCount * 10 ** 18);;

					FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
					this.setState({ _FireFrogsCount: FireFrogsCount });
					console.log("FireFrogsCount:" + FireFrogsCount);

					gameStartTime = await contractStaking.methods.gameStartTime().call();
					this.setState({ _gameStartTime: gameStartTime });
					console.log("gameStartTime:" + gameStartTime);

					gameDuration = await contractStaking.methods.gameDuration().call();
					this.setState({ _gameDuration: gameDuration });
					console.log("gameDuration:" + gameDuration);

					stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
					this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
					console.log("stakingEligibilityTime:" + stakingEligibilityTime);

					additionalTime = await contractStaking.methods.additionalTime().call();
					this.setState({ _additionalTime: additionalTime });
					console.log("additionalTime:" + additionalTime);

					gameEndTime = gameStartTime * 1000 + gameDuration * 1000 + additionalTime * 1000;
					this.setState({ _gameEndTime: gameEndTime });
					console.log("gameEndTime : " + gameEndTime);

					totalSupplyNFT = await contractNFT.methods.totalSupply().call();
					this.setState({ totalSupply: totalSupplyNFT });
					console.log("Total Supply:" + totalSupplyNFT);

					isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
					this.setState({ _isApproved: isApproved });
					console.log(isApproved);

					publicSale = await contractNFT.methods.balanceOf(account).call();
					this.setState({ myNFTWallet: publicSale });

					myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
					console.log("myStakedTokenCount : " + myStakedTokenCount);

					myTokenAmount = await contractToken.methods.balanceOf(account).call();
					this.setState({ _myTokenAmount: myTokenAmount });

					scoreUpdate();

					if (allowance > 0) {
						this.setState({ _approved: true });
						console.log("approvedStormsChosen : " + this.state._approved);

					} else {
						this.setState({ _approved: false });
						console.log("approvedStormsChosen : " + this.state._approved);
					}


					for (let y = 0; y < this.state.myNFTWallet; y++) {
						myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
						this.setState({ _myTokenIDs: myTokenIDs });

					}


					owner = await contractNFT.methods.owner().call();
					console.log("Owner" + owner);


				} catch (err) {
					console.log("err: " + err);

				}

			} catch (error) {
				console.error(error);
				// Handle errors or user rejections
			}
		} 
		} 

		if (localStorage?.getItem('isWalletConnected') === 'true') {

			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider, // required
					options: {
						infuraId: "bf933c3446b3464c988114813a1360ac" // required
					}
				}
			};

			const web3Modal = new Web3Modal({
				network: "mainnet", // optional
				cacheProvider: true, // optional
				providerOptions // required
			});

			const provider = await web3Modal.connect();

			//  Enable session (triggers QR Code modal)
			await provider.enable();

			const web3 = new Web3(provider);
			console.log("provider : " + provider);
			// Subscribe to accounts change
			provider.on("accountsChanged", (accounts) => {
				console.log(accounts);
			});

			// Subscribe to chainId change
			provider.on("chainChanged", (chainId) => {
				console.log(chainId);
			});

			// Subscribe to provider connection
			provider.on("connect", (info) => {
				console.log(info);
			});

			// Subscribe to provider disconnection
			provider.on("disconnect", (error) => {
				console.log(error);
			});

			//  if wallet is connected
			if (web3Modal.cachedProvider) {
				// connected now you can get accounts
				console.log("provider :" + web3Modal.cachedProvider);
				const accounts = await web3.eth.getAccounts();

				account = accounts[0];
				this.setState({ walletAddress: account });

				contractNFT = new web3.eth.Contract(ABINFT, addressNFT);
				console.log("contractNFT :" + contractNFT);

				contractToken = new web3.eth.Contract(ABIToken, addressToken);
				console.log("contractToken :" + contractToken);


				contractStaking = new web3.eth.Contract(ABIStaking, addressStaking);
				console.log("contractStaking :" + contractStaking);

				if (provider) {


					(async () => {


						if (provider != "walletconnect") {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							const chainId = 5;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'Polygon Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://polygon-rpc.com/'],*/

													chainName: 'Goerli  Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],

													/*chainName: 'Base Goerli',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://goerli.base.org'],*/

													/*chainName: 'Sepolia test network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Sepolia test network', decimals: 18, symbol: 'SepoliaETH' },
													rpcUrls: ['https://sepolia.infura.io/v3/'],*/
												},
											],
										});
									}
								}
							}

							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}


								gameIndex = await contractStaking.methods.gameIndex().call();
								this.setState({ _gameIndex: gameIndex });
								console.log("gameIndex:" + gameIndex);

								if (gameIndex != 0) {
									winner = await contractStaking.methods.winner(gameIndex - 1).call();
									this.setState({ _winner: winner });
									console.log("winner:" + winner);


									isAquaFrog = await contractStaking.methods.isAquaFrog(account, gameIndex - 1).call();
									if (isAquaFrog) {
										this.setState({ _isAquaFrog: true });
										console.log("_isAquaFrog : " + this.state._isAquaFrog);
									} else {
										this.setState({ _isAquaFrog: false });
										console.log("_isAquaFrog : " + this.state._isAquaFrog);
									}

									isFireFrog = await contractStaking.methods.isFireFrog(account, gameIndex - 1).call();
									if (isFireFrog) {
										this.setState({ _isFireFrog: true });
										console.log("_isFireFrog : " + this.state._isFireFrog);

									} else {
										this.setState({ _isFireFrog: false });
										console.log("_isFireFrog : " + this.state._isFireFrog);
									}


									rewardsTaken = await contractStaking.methods.rewardsTaken(account, gameIndex - 1).call();
									this.setState({ _rewardsTaken: rewardsTaken });
									console.log("rewardsTaken:" + rewardsTaken);
									if (rewardsTaken) {
										this.setState({ _rewardsTaken: true });
										console.log("rewardsTaken:" + rewardsTaken);
									} else {
										this.setState({ _rewardsTaken: false });
										console.log("rewardsTaken:" + rewardsTaken);
									}

									allowance = await contractToken.methods.allowance(account, addressStaking).call();
									console.log("allowance : " + allowance);

									gameEnded = await contractStaking.methods.gameEnded(gameIndex).call();
									if (gameEnded) {
										this.setState({ _gameEnded: true });
										console.log("gameEnded:" + gameEnded);
									} else {
										this.setState({ _gameEnded: false });
										console.log("gameEnded:" + gameEnded);
									}

									gameEndedPrevious = await contractStaking.methods.gameEnded(gameIndex - 1).call();
									if (gameEndedPrevious) {
										this.setState({ _gameEndedPrevious: true });
										console.log("gameEndedPrevious:" + gameEndedPrevious);
									} else {
										this.setState({ _gameEndedPrevious: false });
										console.log("gameEndedPrevious:" + gameEndedPrevious);
									}

								}

								countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
								this.setState({ _countOfOverallStakers: countOfOverallStakers });
								console.log("countOfOverallStakers:" + countOfOverallStakers);

								AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
								this.setState({ _AquaFrogsCount: AquaFrogsCount });
								console.log("AquaFrogsCount:" + AquaFrogsCount * 10 ** 18);;

								FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
								this.setState({ _FireFrogsCount: FireFrogsCount });
								console.log("FireFrogsCount:" + FireFrogsCount);

								gameStartTime = await contractStaking.methods.gameStartTime().call();
								this.setState({ _gameStartTime: gameStartTime });
								console.log("gameStartTime:" + gameStartTime);

								gameDuration = await contractStaking.methods.gameDuration().call();
								this.setState({ _gameDuration: gameDuration });
								console.log("gameDuration:" + gameDuration);

								stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
								this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
								console.log("stakingEligibilityTime:" + stakingEligibilityTime);

								additionalTime = await contractStaking.methods.additionalTime().call();
								this.setState({ _additionalTime: additionalTime });
								console.log("additionalTime:" + additionalTime);

								gameEndTime = gameStartTime * 1000 + gameDuration * 1000 + additionalTime * 1000;
								this.setState({ _gameEndTime: gameEndTime });
								console.log("gameEndTime : " + gameEndTime);

								totalSupplyNFT = await contractNFT.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
								this.setState({ _isApproved: isApproved });
								console.log(isApproved);

								publicSale = await contractNFT.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
								console.log("myStakedTokenCount : " + myStakedTokenCount);

								myTokenAmount = await contractToken.methods.balanceOf(account).call();
								this.setState({ _myTokenAmount: myTokenAmount });

								scoreUpdate();

								if (allowance > 0) {
									this.setState({ _approved: true });
									console.log("approvedStormsChosen : " + this.state._approved);

								} else {
									this.setState({ _approved: false });
									console.log("approvedStormsChosen : " + this.state._approved);
								}



								for (let y = 0; y < this.state.myNFTWallet; y++) {
									myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
									this.setState({ _myTokenIDs: myTokenIDs });

								}


								owner = await contractNFT.methods.owner().call();
								console.log("Owner" + owner);


							} catch (err) {
								console.log("err: " + err);

							}
						} else {

							const chainId = 5;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'Base Goerli',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://goerli.base.org'],*/

													/*chainName: 'Sepolia test network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Sepolia test network', decimals: 18, symbol: 'SepoliaETH' },
													rpcUrls: ['https://sepolia.infura.io/v3/'],*/

													/*chainName: 'Polygon Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://polygon-rpc.com/'],*/

													chainName: 'Goerli  Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}



								gameIndex = await contractStaking.methods.gameIndex().call();
								this.setState({ _gameIndex: gameIndex });
								console.log("gameIndex:" + gameIndex);

								if (gameIndex != 0) {
									winner = await contractStaking.methods.winner(gameIndex - 1).call();
									this.setState({ _winner: winner });
									console.log("winner:" + winner);


									isAquaFrog = await contractStaking.methods.isAquaFrog(account, gameIndex - 1).call();
									if (isAquaFrog) {
										this.setState({ _isAquaFrog: true });
										console.log("_isAquaFrog : " + this.state._isAquaFrog);
									} else {
										this.setState({ _isAquaFrog: false });
										console.log("_isAquaFrog : " + this.state._isAquaFrog);
									}

									isFireFrog = await contractStaking.methods.isFireFrog(account, gameIndex - 1).call();
									if (isFireFrog) {
										this.setState({ _isFireFrog: true });
										console.log("_isFireFrog : " + this.state._isFireFrog);

									} else {
										this.setState({ _isFireFrog: false });
										console.log("_isFireFrog : " + this.state._isFireFrog);
									}


									rewardsTaken = await contractStaking.methods.rewardsTaken(account, gameIndex - 1).call();
									this.setState({ _rewardsTaken: rewardsTaken });
									console.log("rewardsTaken:" + rewardsTaken);

									if (rewardsTaken) {
										this.setState({ _rewardsTaken: true });
										console.log("rewardsTaken:" + rewardsTaken);
									} else {
										this.setState({ _rewardsTaken: false });
										console.log("rewardsTaken:" + rewardsTaken);
									}

									allowance = await contractToken.methods.allowance(account, addressStaking).call();
									console.log("allowance : " + allowance);

									gameEnded = await contractStaking.methods.gameEnded(gameIndex).call();
									if (gameEnded) {
										this.setState({ _gameEnded: true });
										console.log("gameEnded:" + gameEnded);
									} else {
										this.setState({ _gameEnded: false });
										console.log("gameEnded:" + gameEnded);
									}

									gameEndedPrevious = await contractStaking.methods.gameEnded(gameIndex - 1).call();
									if (gameEndedPrevious) {
										this.setState({ _gameEndedPrevious: true });
										console.log("gameEndedPrevious:" + gameEndedPrevious);
									} else {
										this.setState({ _gameEndedPrevious: false });
										console.log("gameEndedPrevious:" + gameEndedPrevious);
									}

								}

								countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
								this.setState({ _countOfOverallStakers: countOfOverallStakers });
								console.log("countOfOverallStakers:" + countOfOverallStakers);

								AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
								this.setState({ _AquaFrogsCount: AquaFrogsCount });
								console.log("AquaFrogsCount:" + AquaFrogsCount * 10 ** 18);

								FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
								this.setState({ _FireFrogsCount: FireFrogsCount });
								console.log("FireFrogsCount:" + FireFrogsCount);

								gameStartTime = await contractStaking.methods.gameStartTime().call();
								this.setState({ _gameStartTime: gameStartTime });
								console.log("gameStartTime:" + gameStartTime);

								gameDuration = await contractStaking.methods.gameDuration().call();
								this.setState({ _gameDuration: gameDuration });
								console.log("gameDuration:" + gameDuration);

								stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
								this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
								console.log("stakingEligibilityTime:" + stakingEligibilityTime);

								additionalTime = await contractStaking.methods.additionalTime().call();
								this.setState({ _additionalTime: additionalTime });
								console.log("additionalTime:" + additionalTime);

								gameEndTime = gameStartTime * 1000 + gameDuration * 1000 + additionalTime * 1000;
								await new Promise(resolve => setTimeout(resolve, 5000));
								this.setState({ _gameEndTime: gameEndTime });
								console.log("gameEndTime : " + gameEndTime);
								await new Promise(resolve => setTimeout(resolve, 5000));

								totalSupplyNFT = await contractNFT.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });

								console.log("Total Supply:" + totalSupplyNFT);

								isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
								this.setState({ _isApproved: isApproved });
								console.log(isApproved);

								publicSale = await contractNFT.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
								console.log("myStakedTokenCount : " + myStakedTokenCount);

								myTokenAmount = await contractToken.methods.balanceOf(account).call();
								this.setState({ _myTokenAmount: myTokenAmount });

								scoreUpdate();

								if (allowance > 0) {
									this.setState({ _approved: true });
									console.log("approvedStormsChosen : " + this.state._approved);

								} else {
									this.setState({ _approved: false });
									console.log("approvedStormsChosen : " + this.state._approved);
								}

								for (let y = 0; y < this.state.myNFTWallet; y++) {
									myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
									this.setState({ _myTokenIDs: myTokenIDs })

								}


								owner = await contractNFT.methods.owner().call();
								console.log("Owner" + owner);


							} catch (err) {
								console.log("err: " + err);

							}
						}
					})();

					//.....................................................................//

					// Legacy providers may only have ethereum.sendAsync
					const chainId = await provider.request({
						method: 'eth_chainId'
					})


				} else {

					// if the provider is not detected, detectEthereumProvider resolves to null
					console.error('Please install a Valid Wallet');
					alert('A valid provider could not be found!');

				}
			}

		} else {

			console.log("NOT CONNECTED");

		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contractNFT.methods.owner().call();


			//	if (account != owner) {


			try {


				console.log("minAmount:" + mintAmount);
				console.log("valueOfNFTs:" + valueOfNFTs);

				//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

				this.setState({ statusError: false, statusLoading: true });
				await contractNFT.methods.mint(mintAmount * 1).send({ gasLimit: 390000,/*gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */from: account, value: this.state.totalValue * 0 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}

		} catch (err) {

			console.log(err);

		}
	}

	claimWinningRewards = async event => {
		event.preventDefault();

		try {
			this.setState({ statusError: false, statusLoading: true });
			await contractStaking.methods.claimWinningRewards().send({ /*gasLimit: 390000,*/ gasLimit: 390000,/* maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */from: account });
			this.setState({ statusLoading: false, success: true });
		} catch (err) {
			this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
			console.log(err);
		}
	}

	walletConnect2 = async event => {
		event.preventDefault();

		// Check if MetaMask is installed

		this.setState({ _walletConnect2: 1 });

		if (window.ethereum) {
			const provider = window.ethereum;

			try {
				// Request access to the user's MetaMask wallet
				await provider.request({ method: "eth_requestAccounts" });

				// Deep link to your website
				window.location.href = "https://metamask.app.link/dapp/battlefield.frogchain.io/battlefield";
				// window.location.href = "https://go.cb-w.com/dapp?cb_url=https:///stake-frogchain.pages.dev/";




			} catch (error) {
				console.error(error);
			}
		} else {
			// MetaMask is not installed, provide a link to download
			window.location.href = "https://metamask.app.link/dapp/battlefield.frogchain.io/battlefield";
		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		//  if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contractNFT = new web3.eth.Contract(ABINFT, addressNFT);
			console.log("contractNFT :" + contractNFT);

			contractToken = new web3.eth.Contract(ABIToken, addressToken);
			console.log("contractToken :" + contractToken);


			contractStaking = new web3.eth.Contract(ABIStaking, addressStaking);
			console.log("contractStaking :" + contractStaking);

			if (provider) {


				(async () => {


					if (provider != "walletconnect") {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						const chainId = 5;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],*/

												chainName: 'Goerli  Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],

												/*chainName: 'Base Goerli',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://goerli.base.org'],*/

												/*chainName: 'Sepolia test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Sepolia test network', decimals: 18, symbol: 'SepoliaETH' },
												rpcUrls: ['https://sepolia.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}

						try {



							gameIndex = await contractStaking.methods.gameIndex().call();
							this.setState({ _gameIndex: gameIndex });
							console.log("gameIndex:" + gameIndex);

							if (gameIndex != 0) {
								winner = await contractStaking.methods.winner(gameIndex - 1).call();
								this.setState({ _winner: winner });
								console.log("winner:" + winner);


								isAquaFrog = await contractStaking.methods.isAquaFrog(account, gameIndex - 1).call();
								if (isAquaFrog) {
									this.setState({ _isAquaFrog: true });
									console.log("_isAquaFrog : " + this.state._isAquaFrog);
								} else {
									this.setState({ _isAquaFrog: false });
									console.log("_isAquaFrog : " + this.state._isAquaFrog);
								}

								isFireFrog = await contractStaking.methods.isFireFrog(account, gameIndex - 1).call();
								if (isFireFrog) {
									this.setState({ _isFireFrog: true });
									console.log("_isFireFrog : " + this.state._isFireFrog);

								} else {
									this.setState({ _isFireFrog: false });
									console.log("_isFireFrog : " + this.state._isFireFrog);
								}


								rewardsTaken = await contractStaking.methods.rewardsTaken(account, gameIndex - 1).call();
								this.setState({ _rewardsTaken: rewardsTaken });
								console.log("rewardsTaken:" + rewardsTaken);

								if (rewardsTaken) {
									this.setState({ _rewardsTaken: true });
									console.log("rewardsTaken:" + rewardsTaken);
								} else {
									this.setState({ _rewardsTaken: false });
									console.log("rewardsTaken:" + rewardsTaken);
								}

								allowance = await contractToken.methods.allowance(account, addressStaking).call();
								console.log("allowance : " + allowance);

								gameEnded = await contractStaking.methods.gameEnded(gameIndex).call();
								if (gameEnded) {
									this.setState({ _gameEnded: true });
									console.log("gameEnded:" + gameEnded);
								} else {
									this.setState({ _gameEnded: false });
									console.log("gameEnded:" + gameEnded);
								}

								gameEndedPrevious = await contractStaking.methods.gameEnded(gameIndex - 1).call();
								if (gameEndedPrevious) {
									this.setState({ _gameEndedPrevious: true });
									console.log("gameEndedPrevious:" + gameEndedPrevious);
								} else {
									this.setState({ _gameEndedPrevious: false });
									console.log("gameEndedPrevious:" + gameEndedPrevious);
								}

							}

							countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
							this.setState({ _countOfOverallStakers: countOfOverallStakers });
							console.log("countOfOverallStakers:" + countOfOverallStakers);

							AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
							this.setState({ _AquaFrogsCount: AquaFrogsCount });
							console.log("AquaFrogsCount:" + AquaFrogsCount * 10 ** 18);;

							FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
							this.setState({ _FireFrogsCount: FireFrogsCount });
							console.log("FireFrogsCount:" + FireFrogsCount);

							gameStartTime = await contractStaking.methods.gameStartTime().call();
							this.setState({ _gameStartTime: gameStartTime });
							console.log("gameStartTime:" + gameStartTime);

							gameDuration = await contractStaking.methods.gameDuration().call();
							this.setState({ _gameDuration: gameDuration });
							console.log("gameDuration:" + gameDuration);

							stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
							this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
							console.log("stakingEligibilityTime:" + stakingEligibilityTime);

							additionalTime = await contractStaking.methods.additionalTime().call();
							this.setState({ _additionalTime: additionalTime });
							console.log("additionalTime:" + additionalTime);

							gameEndTime = gameStartTime * 1000 + gameDuration * 1000 + additionalTime * 1000;
							this.setState({ _gameEndTime: gameEndTime });
							console.log("gameEndTime : " + gameEndTime);

							totalSupplyNFT = await contractNFT.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
							this.setState({ _isApproved: isApproved });
							console.log(isApproved);

							publicSale = await contractNFT.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
							console.log("myStakedTokenCount : " + myStakedTokenCount);

							myTokenAmount = await contractToken.methods.balanceOf(account).call();
							this.setState({ _myTokenAmount: myTokenAmount });

							scoreUpdate();

							if (allowance > 0) {
								this.setState({ _approved: true });
								console.log("approvedStormsChosen : " + this.state._approved);

							} else {
								this.setState({ _approved: false });
								console.log("approvedStormsChosen : " + this.state._approved);
							}


							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs });

							}


							owner = await contractNFT.methods.owner().call();
							console.log("Owner" + owner);


						} catch (err) {
							console.log("err: " + err);

						}
					} else {


						const chainId = 5;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Base Goerli',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://goerli.base.org'],*/

												/*chainName: 'Sepolia test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Sepolia test network', decimals: 18, symbol: 'SepoliaETH' },
												rpcUrls: ['https://sepolia.infura.io/v3/'],*/

												/*chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],*/

												chainName: 'Goerli  Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}


							gameIndex = await contractStaking.methods.gameIndex().call();
							this.setState({ _gameIndex: gameIndex });
							console.log("gameIndex:" + gameIndex);

							if (gameIndex != 0) {
								winner = await contractStaking.methods.winner(gameIndex - 1).call();
								this.setState({ _winner: winner });
								console.log("winner:" + winner);


								isAquaFrog = await contractStaking.methods.isAquaFrog(account, gameIndex - 1).call();
								if (isAquaFrog) {
									this.setState({ _isAquaFrog: true });
									console.log("_isAquaFrog : " + this.state._isAquaFrog);
								} else {
									this.setState({ _isAquaFrog: false });
									console.log("_isAquaFrog : " + this.state._isAquaFrog);
								}

								isFireFrog = await contractStaking.methods.isFireFrog(account, gameIndex - 1).call();
								if (isFireFrog) {
									this.setState({ _isFireFrog: true });
									console.log("_isFireFrog : " + this.state._isFireFrog);

								} else {
									this.setState({ _isFireFrog: false });
									console.log("_isFireFrog : " + this.state._isFireFrog);
								}


								rewardsTaken = await contractStaking.methods.rewardsTaken(account, gameIndex - 1).call();

								if (rewardsTaken) {
									this.setState({ _rewardsTaken: true });
								} else {
									this.setState({ _rewardsTaken: false });
								}
								console.log("rewardsTaken:" + rewardsTaken);

								allowance = await contractToken.methods.allowance(account, addressStaking).call();
								console.log("allowance : " + allowance);

								gameEnded = await contractStaking.methods.gameEnded(gameIndex).call();
								if (gameEnded) {
									this.setState({ _gameEnded: true });
									console.log("gameEnded:" + gameEnded);
								} else {
									this.setState({ _gameEnded: false });
									console.log("gameEnded:" + gameEnded);
								}

								gameEndedPrevious = await contractStaking.methods.gameEnded(gameIndex - 1).call();
								if (gameEndedPrevious) {
									this.setState({ _gameEndedPrevious: true });
									console.log("gameEndedPrevious:" + gameEndedPrevious);
								} else {
									this.setState({ _gameEndedPrevious: false });
									console.log("gameEndedPrevious:" + gameEndedPrevious);
								}

							}

							countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
							this.setState({ _countOfOverallStakers: countOfOverallStakers });
							console.log("countOfOverallStakers:" + countOfOverallStakers);

							AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
							this.setState({ _AquaFrogsCount: AquaFrogsCount });
							console.log("AquaFrogsCount:" + AquaFrogsCount * 10 ** 18);

							FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
							this.setState({ _FireFrogsCount: FireFrogsCount });
							console.log("FireFrogsCount:" + FireFrogsCount);

							gameStartTime = await contractStaking.methods.gameStartTime().call();
							this.setState({ _gameStartTime: gameStartTime });
							console.log("gameStartTime:" + gameStartTime);

							gameDuration = await contractStaking.methods.gameDuration().call();
							this.setState({ _gameDuration: gameDuration });
							console.log("gameDuration:" + gameDuration);

							stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
							this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
							console.log("stakingEligibilityTime:" + stakingEligibilityTime);

							additionalTime = await contractStaking.methods.additionalTime().call();
							this.setState({ _additionalTime: additionalTime });
							console.log("additionalTime:" + additionalTime);

							gameEndTime = gameStartTime * 1000 + gameDuration * 1000 + additionalTime * 1000;
							await new Promise(resolve => setTimeout(resolve, 5000));
							this.setState({ _gameEndTime: gameEndTime });
							console.log("gameEndTime : " + gameEndTime);
							await new Promise(resolve => setTimeout(resolve, 5000));

							totalSupplyNFT = await contractNFT.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);


							isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
							this.setState({ _isApproved: isApproved });
							console.log(isApproved);

							publicSale = await contractNFT.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
							console.log("myStakedTokenCount : " + myStakedTokenCount);

							myTokenAmount = await contractToken.methods.balanceOf(account).call();
							this.setState({ _myTokenAmount: myTokenAmount });

							scoreUpdate();

							if (allowance > 0) {
								this.setState({ _approved: true });
								console.log("approvedStormsChosen : " + this.state._approved);

							} else {
								this.setState({ _approved: false });
								console.log("approvedStormsChosen : " + this.state._approved);
							}


							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}


							owner = await contractNFT.methods.owner().call();
							console.log("Owner" + owner);


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})


			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}
		}

	}

	mm = async event => {
		event.preventDefault();

		//const userAgent = window.navigator.userAgent;
		//const isMobile = /Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Mobile|webOS|Opera Mini|IEMobile|Mobile Safari|SamsungBrowser|Nokia/.test(userAgent);
		//const isMetaMaskBrowser = window.ethereum && window.ethereum.isMetaMask;

		//if (isMobile && isMetaMaskBrowser) {

		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {
				localStorage.setItem('isWalletConnectedMM', true);
			} catch (ex) {
				console.log(ex)
			}

			try {

				const web3mm = new Web3(window.ethereum);

				// Request access to user accounts
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log("metamask on PC")
				// Continue with your DApp logic
				const accounts = await web3mm.eth.getAccounts();
				const account = accounts[0];

				// Fetch data from contracts, etc.
				// ...
				// Fetch data from contracts
				const contractNFT = new web3mm.eth.Contract(ABINFT, addressNFT);
				const contractToken = new web3mm.eth.Contract(ABIToken, addressToken);
				const contractStaking = new web3mm.eth.Contract(ABIStaking, addressStaking);


				// Update your state with the wallet address
				this.setState({ walletAddress: account });

				// Rest of your MetaMask-specific logic can go here
				// ...

				const chainId = 5;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {

							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
										rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

										/*chainName: 'Base Goerli',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://goerli.base.org'],*/

										chainName: 'Goerli  Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
										rpcUrls: ['https://goerli.infura.io/v3/'],

										/*chainName: 'BNB Smart Chain Mainnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'BNB Smart Chain Mainnet', decimals: 18, symbol: 'BNB' },
										rpcUrls: ['https://bsc-dataseed.binance.org'],*/

									},
								],
							});
						}
					}
				}
				try {

					gameIndex = await contractStaking.methods.gameIndex().call();
					this.setState({ _gameIndex: gameIndex });
					console.log("gameIndex:" + gameIndex);

					if (gameIndex != 0) {
						winner = await contractStaking.methods.winner(gameIndex - 1).call();
						this.setState({ _winner: winner });
						console.log("winner:" + winner);


						isAquaFrog = await contractStaking.methods.isAquaFrog(account, gameIndex - 1).call();
						if (isAquaFrog) {
							this.setState({ _isAquaFrog: true });
							console.log("_isAquaFrog : " + this.state._isAquaFrog);
						} else {
							this.setState({ _isAquaFrog: false });
							console.log("_isAquaFrog : " + this.state._isAquaFrog);
						}

						isFireFrog = await contractStaking.methods.isFireFrog(account, gameIndex - 1).call();
						if (isFireFrog) {
							this.setState({ _isFireFrog: true });
							console.log("_isFireFrog : " + this.state._isFireFrog);

						} else {
							this.setState({ _isFireFrog: false });
							console.log("_isFireFrog : " + this.state._isFireFrog);
						}


						rewardsTaken = await contractStaking.methods.rewardsTaken(account, gameIndex - 1).call();
						this.setState({ _rewardsTaken: rewardsTaken });
						console.log("rewardsTaken:" + rewardsTaken);

						if (rewardsTaken) {
							this.setState({ _rewardsTaken: true });
							console.log("rewardsTaken:" + rewardsTaken);
						} else {
							this.setState({ _rewardsTaken: false });
							console.log("rewardsTaken:" + rewardsTaken);
						}

						allowance = await contractToken.methods.allowance(account, addressStaking).call();
						console.log("allowance : " + allowance);

						gameEnded = await contractStaking.methods.gameEnded(gameIndex).call();
						if (gameEnded) {
							this.setState({ _gameEnded: true });
							console.log("gameEnded:" + gameEnded);
						} else {
							this.setState({ _gameEnded: false });
							console.log("gameEnded:" + gameEnded);
						}

						gameEndedPrevious = await contractStaking.methods.gameEnded(gameIndex - 1).call();
						if (gameEndedPrevious) {
							this.setState({ _gameEndedPrevious: true });
							console.log("gameEndedPrevious:" + gameEndedPrevious);
						} else {
							this.setState({ _gameEndedPrevious: false });
							console.log("gameEndedPrevious:" + gameEndedPrevious);
						}

					}

					countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
					this.setState({ _countOfOverallStakers: countOfOverallStakers });
					console.log("countOfOverallStakers:" + countOfOverallStakers);

					AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
					this.setState({ _AquaFrogsCount: AquaFrogsCount });
					console.log("AquaFrogsCount:" + AquaFrogsCount * 10 ** 18);;

					FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
					this.setState({ _FireFrogsCount: FireFrogsCount });
					console.log("FireFrogsCount:" + FireFrogsCount);

					gameStartTime = await contractStaking.methods.gameStartTime().call();
					this.setState({ _gameStartTime: gameStartTime });
					console.log("gameStartTime:" + gameStartTime);

					gameDuration = await contractStaking.methods.gameDuration().call();
					this.setState({ _gameDuration: gameDuration });
					console.log("gameDuration:" + gameDuration);

					stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
					this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
					console.log("stakingEligibilityTime:" + stakingEligibilityTime);

					additionalTime = await contractStaking.methods.additionalTime().call();
					this.setState({ _additionalTime: additionalTime });
					console.log("additionalTime:" + additionalTime);

					gameEndTime = gameStartTime * 1000 + gameDuration * 1000 + additionalTime * 1000;
					this.setState({ _gameEndTime: gameEndTime });
					console.log("gameEndTime : " + gameEndTime);

					totalSupplyNFT = await contractNFT.methods.totalSupply().call();
					this.setState({ totalSupply: totalSupplyNFT });
					console.log("Total Supply:" + totalSupplyNFT);

					isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
					this.setState({ _isApproved: isApproved });
					console.log(isApproved);

					publicSale = await contractNFT.methods.balanceOf(account).call();
					this.setState({ myNFTWallet: publicSale });

					myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
					console.log("myStakedTokenCount : " + myStakedTokenCount);

					myTokenAmount = await contractToken.methods.balanceOf(account).call();
					this.setState({ _myTokenAmount: myTokenAmount });

					scoreUpdate();

					if (allowance > 0) {
						this.setState({ _approved: true });
						console.log("approvedStormsChosen : " + this.state._approved);

					} else {
						this.setState({ _approved: false });
						console.log("approvedStormsChosen : " + this.state._approved);
					}


					for (let y = 0; y < this.state.myNFTWallet; y++) {
						myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
						this.setState({ _myTokenIDs: myTokenIDs });

					}


					owner = await contractNFT.methods.owner().call();
					console.log("Owner" + owner);


				} catch (err) {
					console.log("err: " + err);

				}

			} catch (error) {
				console.error(error);
				// Handle errors or user rejections
			}
		} else {
			window.open("https://metamask.app.link/dapp/battleplay.pages.dev/");
		}
	};

	walletDisconnect = async event => {
		event.preventDefault();

		const userAgent = window.navigator.userAgent;

		const isMobile = /Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Mobile|webOS|Opera Mini|IEMobile|Mobile Safari|SamsungBrowser|Nokia/.test(userAgent);
		const isMetaMaskBrowser = window.ethereum && window.ethereum.isMetaMask;

		if (isMobile && isMetaMaskBrowser) {
			await window.ethereum.close();
		} else {


			// If not in the MetaMask browser, use Web3Modal for disconnection
			const web3Modal = new Web3Modal({
				network: "mainnet",
				cacheProvider: true,
				providerOptions: {
					walletconnect: {
						package: WalletConnectProvider,
						options: {
							infuraId: "bf933c3446b3464c988114813a1360ac"
						}
					}
				}
			});

			// Clear cached provider to disconnect
			web3Modal.clearCachedProvider();

			// Reload the page to reset the DApp state
			window.location.reload(true);

			try {
				localStorage.setItem('isWalletConnected', false);
			} catch (ex) {
				console.error(ex);
			}

		}


	}

	setApprovalForAll = async event => {
		event.preventDefault();

		try {
			await contractNFT.methods.setApprovalForAll(addressStaking, true).send({/*gasLimit: 390000,*/ /* gasLimit: 285000, */  gasLimit: 390000, from: account });

			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}

	}

	async stake(staking_id) {


		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + staking_id);

		try {
			isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
			this.setState({ _isApproved: isApproved });
			console.log(isApproved);

			if (isApproved) {
				await contractStaking.methods.stakeNFT(staking_id).send({ /*maxPriorityFeePerGas: 40000000000, gasLimit: 285000,*/ /*gasLimit: 390000,*/  gasLimit: 390000, from: account });

			} else {

				await contractNFT.methods.setApprovalForAll(addressStaking, true).send({ /*maxPriorityFeePerGas: 40000000000, gasLimit: 285000,*/ /*gasLimit: 390000,*/  gasLimit: 390000, from: account });

			}

			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}


	}

	batchStake = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + myTokenIDs);

		try {
			isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
			this.setState({ _isApproved: isApproved });
			console.log(isApproved);

			if (isApproved) {
				await contractStaking.methods.batchStakeNFT(myTokenIDs).send({ /*maxPriorityFeePerGas: 40000000000, gasLimit: 285000,*/ gasLimit: 390000, from: account });

			} else {

				await contractNFT.methods.setApprovalForAll(addressStaking, true).send({ /*maxPriorityFeePerGas: 40000000000, gasLimit: 285000,*/ gasLimit: 390000, from: account });

			}

			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}

	}

	batchUnstake = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + getMyStakedTokens);

		try {

			await contractStaking.methods.batchUnstakeNFT(getMyStakedTokens).send({ /*maxPriorityFeePerGas: 40000000000,/*gasLimit: 285000,*/ gasLimit: 390000, from: account });
			window.location.reload(true);


		} catch (err) {
			console.log(err);
		}

	}

	batchClaimRewards = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + getMyStakedTokens);

		try {

			await contractStaking.methods.batchClaimRewards(getMyStakedTokens).send({ /*maxPriorityFeePerGas: 40000000000,*/ /*gasLimit: 285000,*/ gasLimit: 390000, from: account });
			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}

	}

	async claimRewards(claiming_id) {

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + claiming_id);

		try {

			await contractStaking.methods.claimRewards(claiming_id).send({ /*maxPriorityFeePerGas: 40000000000,*/ /*gasLimit: 390000,*/ /*gasLimit: 285000,*/ gasLimit: 390000, from: account });
			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}


	}

	async unstake(unstaking_id) {

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + unstaking_id);

		try {

			await contractStaking.methods.unstakeNFT(unstaking_id).send({ /*maxPriorityFeePerGas: 40000000000,*/ gasLimit: 390000, from: account });
			window.location.reload(true);


		} catch (err) {
			console.log(err);
		}


	}

	getStakedDetails = async event => {
		event.preventDefault();

		if (this.state.walletAddress != "") {
			getStakedDetailsWindow = 1;
			this.setState({ _getStakedDetailsWindow: getStakedDetailsWindow });
			console.log("getStakedDetailsWindow :" + getStakedDetailsWindow);
		}

		try {

			myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
			console.log("myStakedTokenCount : " + myStakedTokenCount);

			let a;
			for (a = 0; a < myStakedTokenCount; a++) {
				getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
				//	getRewards = await contractStaking.methods.getRewards(getMyStakedTokens[a]).send({ from: account });

				let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
				let timeDuration = Date.now() - (tokenTime * 1000);

				if (timeDuration >= 0) {

					getRewards[a] = ((Date.now() - (tokenTime * 1000)) * 10) / 86400000;

				} else {
					getRewards[a] = 0;
				}

				elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(2)];
				this.setState({ _elementArray: elementArray });

				this.setState({ _getRewards: getRewards });
				console.log("tokenTime :" + tokenTime * 1000);
				console.log("tokenTimeDuration :" + timeDuration);
				console.log("getRewards : " + getRewards[a].toFixed(2));

				this.setState({ _getMyStakedTokens: getMyStakedTokens });
				console.log("getMyStakedTokens : " + getMyStakedTokens);
				console.log("elementArray[a] : " + elementArray[a]);
				console.log("elementArray[a][0] : " + elementArray[a][0]);

			}


			/*for (let q = 0; q < getRewards.length; q++) {
				pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
				this.setState({ _newPendingRewards: pendingRewards * 1 });
				console.log("_newPendingRewards :" + this.state._newPendingRewards);

			}*/


		} catch (err) {
			console.log(err);
		}

	}

	getMyOwnNFTDetails = async event => {
		event.preventDefault();

		if (this.state.walletAddress != "") {
			getMyOwnNFTDetailsWindow = 1;
			this.setState({ _getMyOwnNFTDetailsWindow: getMyOwnNFTDetailsWindow });
			console.log("getMyOwnNFTDetailsWindow :" + getMyOwnNFTDetailsWindow);
		}

		try {


			for (let y = 0; y < this.state.myNFTWallet; y++) {
				myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
				this.setState({ _myTokenIDs: myTokenIDs })

			}


		} catch (err) {
			console.log(err);
		}

	}

	closeWindow = async event => {
		event.preventDefault();

		getStakedDetailsWindow = 0;
		this.setState({ _getStakedDetailsWindow: getStakedDetailsWindow });

	}

	closeWindow2 = async event => {
		event.preventDefault();

		getMyOwnNFTDetailsWindow = 0;
		this.setState({ _getMyOwnNFTDetailsWindow: getMyOwnNFTDetailsWindow });

	}


	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	/*moveDemonicInsight = async event => {
		event.preventDefault();

		try {
			
			$(".bg2").animate({
				right: "100%"
			},
	
				1000,
	
				function () {
					$(this).hide();
					//$(this).css("right", "-212px");
				}
			);

		} catch (err) {
			console.log(err);
		}
		window.location.reload(true);

	}*/

	moveDemonicInsight = () => {
		$('.btn1').fadeOut('slow');

		$('.bg2').animate({ marginRight: '-100%' }, "slow", function () {
			//$('.bg2').hide();
			$('.formMain').show('fast').animate({ marginTop: "0" }, "slow");
			$('.formMain2').show('fast');
			$('.battleBtn').show('fast');
			//	$(".bg1").css({ marginLeft: "0" }).show().animate({ marginLeft: "0" }, "slow");
		});
	}

	moveStormsChosen = () => {
		$('.btn2').fadeOut('fast');

		$(".bg1").animate({ marginLeft: "-100%" }, "slow", function () {
			//$(this).hide();

			//$(".bg2").css({ marginLeft: "100%" }).animate({ marginLeft: "0" }, "slow");
		});
		$('.formMain-2').show('fast').animate({ marginTop: "0" }, "slow");
		$('.formMain2-2').show('fast');
		$('.battleBtn-2').show('fast');
	}


	stakeFrog_AquaFrogs = async event => {

		event.preventDefault();

		await contractStaking.methods.stakeFrog_AquaFrogs(this.state._stakeFrog_AquaFrogsTokens).send({ gasLimit: 385000/*gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */, from: account });
		this.setState({ _stakeFrog_AquaFrogsTokens: stakeFrog_AquaFrogs });
		console.log("stakeFrog_AquaFrogs : " + this.state._stakeFrog_AquaFrogsTokens);
	}

	stakeFrog_FireFrogsFunction = async event => {

		event.preventDefault();

		await contractStaking.methods.stakeFrog_FireFrogs(this.state._stakeFrog_FireFrogsTokens).send({ gasLimit: 385000,/*gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */from: account });
		this.setState({ _stakeFrog_FireFrogsTokens: stakeFrog_FireFrogs });
		console.log("stakeFrog_FireFrogs : " + this.state._stakeFrog_FireFrogsTokens);
	}

	approveFunction = async event => {
		event.preventDefault();

		try {

			allowance = await contractToken.methods.allowance(account, addressStaking).call();
			console.log("allowance : " + allowance);

			if (allowance > 0) {

				await contractToken.methods.increaseAllowance(addressStaking, '10000000000000000000000').send({ gasLimit: 385000, from: account });

			} else {

				await contractToken.methods.approve(addressStaking, '10000000000000000000000').send({ gasLimit: 385000, from: account });

			}

			window.location.reload(true);

		} catch (err) {
			this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
			console.log(err);

		}

		this.moveDemonicInsight();

	}


	closeWindow3 = async event => {
		event.preventDefault();

		this.setState({ statusError: false })
	}

	render() {

		return (
			<div class="allWrap">

				<div class="light">
					<div class="page-loader">
						<div class="containerLoad">
							<div class="ring">
								<div class="h1Load">Loading...</div>
							</div>
						</div>
					</div>
					<Element name="up">

						{this.state._navbarOpen < 1 ?
							(

								<div class="miniBarMain">
									<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect} class="connect2-2">
												<button class="button-92">
													Connect
												</button>
											</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
												<button class="button-92">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											</form>)}
										<div class="connect2btns">
											<form class="connect2-2-Mob" onClick={this.walletConnect}>

												<img class="mm" onClick={this.mm} src={mm} />

											</form>

											{this.state.walletAddress === '' ?
												(<form onClick={this.walletConnect} class="connect2-2-Mob"  >
													<img onClick={this.walletConnect} class="mm" src={wc} />
												</form>) : (<form onSubmit={this.walletDisconnect}>
													<button class="connect2-2-Mob-2">
														{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

													</button>
												</form>)}
										</div>

									</div>
									<div class="navbarMainImgSet"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
								</div>) :
							(<div class="miniBarMain">
								<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>
								<div class="connect2">
									{this.state.walletAddress === '' ?
										(<form onSubmit={this.walletConnect} class="connect2-2">
											<button class="button-92">
												Connect
											</button>
										</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
											<button class="button-92">
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

											</button>
										</form>)}
									<div class="connect2btns">
										<form class="connect2-2-Mob" onClick={this.walletConnect}>

											<img class="mm" onClick={this.mm} src={mm} />

										</form>

										{this.state.walletAddress === '' ?
											(<form onClick={this.walletConnect} class="connect2-2-Mob"  >
												<img onClick={this.walletConnect} class="mm" src={wc} />
											</form>) : (<form onSubmit={this.walletDisconnect}>
												<button class="connect2-2-Mob-2">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											</form>)}
									</div>

								</div>
								<div class="navbarMainImgSet"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
							</div>)}

						<div class="headers">

							<div class="h1">
								<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>

								<div class="connect">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550} onClick={home}>Home</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={mintSite}>Mint</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={stakeSite}>Staking</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={() => window.location.href = '/'}>Battle Preparation</Link></div>
								</div>

								<div class="right">

									<div class="icons">
										<img onClick={opensea} src={os} />
										<img onClick={tweet} src={twitter} />
										<img onClick={tg} src={telegram} />
									</div>
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect} class="connect2-2">
												<button class="button-92">
													Connect
												</button>
											</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
												<button class="button-92">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											</form>)}

										{this.state._walletConnect2 < 1 ?

											(<form onSubmit={this.walletConnect2} class="connect2-2-Mob">
												<button class="button-92">Connect</button>
											</form>) :

											this.state.walletAddress === '' ?

												(<form onSubmit={this.walletConnect} class="connect2-2">
													<button class="button-92">
														Connect2
													</button>
												</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
													<button class="button-92">
														{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

													</button>
												</form>)}

									</div>

								</div>


								<div class="connectMob">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
								</div>



							</div>
						</div>

						{this.state._navbarOpen > 0 ?
							(<div class="littleNav" data-aos="fade-left">

								<div ><Link activeClass="" id="fontSize2" to="story" spy={true} smooth={true} duration={550} onClick={home}>Home</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={mintSite}>Mint</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={stakeSite}>Staking</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={() => window.location.href = '/'}>Battle Preparation</Link></div>


								<div class="icons">
									<img onClick={opensea} src={os} />
									<img onClick={tweet} src={twitter} />
									<img onClick={tg} src={telegram} />
								</div>
							</div>) : null}
					</Element>


					<div class="cont">

						<div class="notificationMain"><div class="notification1"><img class="bell" src={bell} /><span class="special5">"Prepare for an epic showdown!"</span></div>
						</div>

						<div class="teams2">
							<div class="teams">

								<div><div class="t1">AQUA FROGS</div>
									<img src={blue} />
								</div>

								<div class="vs">VS</div>

								<div><div class="t2">FIRE FROGS</div>
									<img src={orange} />
								</div>

							</div>


							<div class="progress-bar">
								<div class="team1-progress">{(this.state._AquaFrogsCount)}</div>
								<div class="team2-progress">{(this.state._FireFrogsCount)}</div>
							</div>

							{this.state._gameEndTime !== '' ?
								(<div class="hlT">
									{Date.now() < this.state._gameEndTime /*1665345600000*/ ?
										(<div>
											<div class="time1">BATTLE ENDS IN</div>

											<div class="countdown">
												<Countdown
													date={this.state._gameEndTime/*1665345600000*/}
													renderer={renderer}
												/>
											</div>
										</div>
										) :

										(<div>

											{this.state._winner === '1' && this.state._gameEndedPrevious ?
												(<div>
													{this.state._isAquaFrog ?

														(<div class="blur"><div class="congratz"><img class="popper" src={popper} /> Congratulations <img class="popper" src={popper} /></div>

															{this.state._rewardsTaken === false ?
																(<button class="cliamRewards" onClick={this.claimWinningRewards}>Claim Your $FROG</button>) :
																(<div><center><p class="special" onClick={() => window.location.href = '/'}>You already claimed the rewards</p></center></div>)}
														</div>) : (<div class="blur"><div class="congratz"> Sorry, you lose.<br /> Try the next round </div>
														</div>)}
												</div>) : null

											}

											{(this.state._winner === '2') && (this.state._gameEndedPrevious) ?

												(<div>

													{this.state._isFireFrog ?
														(<div class="blur"><div class="congratz"><img class="popper" src={popper} /> Congratulations <img class="popper" src={popper} /></div>

															{this.state._rewardsTaken === false ?
																(<button class="cliamRewards" onClick={this.claimWinningRewards}>Claim Your $FROG</button>) :
																(<div><center><p class="special" onClick={() => window.location.href = '/'}>You already claimed the rewards</p></center></div>)}
														</div>) : (<div class="blur"><div class="congratz"> Sorry, you lose.<br /> Try the next round </div>
														</div>)}
												</div>
												) : null

											}



										</div>)

									}
								</div>) :
								(<div class="hlT">

								</div>)}

							{/*this.state._gameEndTime < Date.now() && this.state._gameEnded === false ?
								(
									<div class="blur"><div class="loadingText">Processing Rewards </div>
									</div>
								) : null*/}

							<p></p>

						</div>

						{this.state.statusLoading ? (
							<div class="formMain2New">
								<div class="formMain-2New">

									<div>
										<div class="lds-dual-ring"></div>
										<p></p>
										<div class="success-message">
											<p>Loading</p>
										</div>
									</div>
								</div>

							</div>)
							: null}

						{this.state.statusError ? (
							<div class="formMain2New">
								<button onClick={this.closeWindow3} class="closeNote">✖</button>
								<div class="formMain-2New">

									<div>
										<div class="success-message">
											<h2 class="special3">Sorry something went wrong. Pls try again later</h2>
										</div>
									</div>
								</div>

							</div>)
							: null}


						{this.state.success ? (
							<div class="formMain2New">
								<div class="formMain-2New">

									<div>
										<img class="check" src={check} />

										<div class="success-message">
											<h2 class="special2">You have successfully claimed the prize</h2>
											<p class="special" onClick={() => window.location.href = '/'}>Join the next round when it starts</p>
										</div>
									</div>
								</div>

							</div>)
							: null}

						<div class="notification"><div class="notification1"><img class="bell" src={bell2} />Winners please collect your rewards before next round starts</div>
						</div>
					</div>




				</div>
			</div>)
	}
}

export default Game;